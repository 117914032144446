@charset "utf-8";



// Our variables
$base-font-family: "Raleway", "Verdana", Arial, sans-serif;
$base-font-size:   13px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$menu-font-size: 14px;
$foot-font-size: 12px;
$base-line-height: 1.5;

$spacing-unit:     10px;

$text-color:       #787274;
$brand-color:      #898788;

$header-color: #F8F5EE;
//$background-color: #ffffff; //#f9f8f1;
$background-color: #fdfcfb;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    1060px;

$on-palm:          600px;
$on-laptop:        800px;

$small-height: 800px;
$med-height: 950px;


// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}
@mixin height-media-query($device) {
    @media screen and (max-height: $device) {
        @content;
    }
}




// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;
