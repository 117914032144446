/**
 * Site header
 */
.site-header {
    background-color: $header-color;
    min-height: 100px;

    // Positioning context for the mobile navigation icon
    position: fixed;
width: 100%;
top: 0;
left: 0;
z-index: 998;
}

.site-title {
    font-size: 26px;
    font-weight: 300;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}

.site-nav {
    float: right;
    margin-top: 60px;
    .menu-icon {
        display: none;
    }

    .pagelink {
        font-size: $menu-font-size;
        color: $brand-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 30px;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        //top: 9px;
        right: $spacing-unit / 2;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 36px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .pagelink {
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    margin-top: 35px;
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
    font-size: $foot-font-size;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}
click
.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    margin: 100px 0 0 0;
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}


body, * {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.logo-small {
    background-size: 89px 30px;
    background: url("/resources/agfe30.png") left top no-repeat;
    width: 89px;
    height: 30px;
}
.logo-large {
    //background-size: 293px 103px;
    //background: url("/resources/agfeslogo.svg") no-repeat center center ;
    background: transparent url("/resources/agfeslogo.png") center center no-repeat;
    background-image: -webkit-linear-gradient(transparent, transparent), url("/resources/agfeslogo.svg");
    background-image: linear-gradient(transparent, transparent), url("/resources/agfeslogo.svg");
    background-size: contain;
    margin: 0 auto;
    height: 100%;
    min-height: 180px;
    max-height: 220px;
}

.slogan {
    font-family: "Cantarell", "Verdana", "arial", "sans-serif";
    font-size: 40px;
    text-align: center;
    margin-bottom: 60px;
}

.panels-wrapper {
    margin-left: -8px;
    border-top: 1px solid $grey-color-light;
    border-bottom: 1px solid $grey-color-light;
 /*   margin-right: -8px; */
    background-color: #fff;
    /*border-bottom: 1px solid $grey-color-light;*/
    @extend %clearfix;
    margin-bottom: 80px;
}
.panel {
        width: 50%;
        padding: 8px;
        float: left;
    }
@include media-query($on-laptop) {
    .panel {
        width: 100%;
        padding: 8px;
        float: left;
    }
}
@include media-query($on-palm) {
.panel {
        width: 100%;
        padding: 8px;
        float: left;
    }

}
.panel-title {
    font-family: "Cantarell", "Verdana", "arial", "sans-serif";
    font-size: 20px;
    margin-bottom: 0.5em;
}
.panel-content {
    font-size: 13px;
}

.footer {
    margin-top: 25px;
    font-size: 12px;
}
.footer-section {
    border-top: 1px solid #C5C2B9;
    line-height: 1.5em;
}

.footer-text {
    margin-top: 1.5em;
    text-align: center;
}

.footer-text p {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
}

.footer-text a {
    color: #898788;
}
.team-wrapper {
    margin-left: -15px;
    margin-right: 0px;
}
.team {
    width: 33%;
    padding: 15px;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 2em;
}
@include media-query($on-laptop) {
.team {
    width: 49%;
    padding: 10px;
}
.team-wrapper {
    margin-left: -10px;
    margin-right: 0px;
}
}
@include media-query($on-palm) {
.team {
    width: 100%;
    padding: 10px;
}
.team-wrapper {
    margin-left: -10px;
    margin-right: 0px;
}
}

.team-title {
    font-family: "Cantarell", "Verdana", "arial", "sans-serif";
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 1em;
}
.team-name {
    font-family: "Cantarell", "Verdana", "arial", "sans-serif";
    font-size: 20px;
    margin-bottom: 0.3em;
}


h2,h3 {
    font-family: "Cantarell", "Verdana", "arial", "sans-serif";
    font-weight: normal;
}
h2 {
    padding-bottom: 10px;
    border-bottom: 1px solid #F8F5EE;
    font-size: 22px;
    margin-top: 1.5em;
    margin-bottom: 1em;
}
h3 {
    margin-top: 1.5em;
    margin-bottom: 1em;
    }
h4 {
    font-weight: bold;
    font-size: 13px;
}

.cols {
    display: inline-block;
    vertical-align: top;
    }
.half {
    width: 49%;
    }
.sep {
    border-right: 1px solid #F8F5EE;
    }
.sepl {
    margin-left: -1px;
    border-left: 1px solid #F8F5EE;
    }
.app-col {
text-align: center;
}
.app-col .app-cell {
padding: 20px;
margin-bottom: 5px;
height: 200px;
}

.app-col .app-cell-small {
height: 150px;
}
.app-col .app-cell-large {
height: 215px;
}

@include media-query($on-laptop) {
.app-col {
text-align: left;
}
.half {
    width: 99%;
    }
.sep {
border-right: 0;
}
.app-col .app-cell {
    height: auto;
    }
}
@include media-query($on-palm) {
.half {
    width: 99%;
    }
.sep {
border-right: 0;
}
}
h3 {

    font-family: "Cantarell", "Verdana", "arial", "sans-serif";
    margin-bottom: 1em;
    font-size: 20px;
//    text-transform: uppercase;
//    text-align: left;
    }


.app-icon {
    margin-bottom: 10px;
}
.app-name {
    font-family: "Cantarell", "Verdana", "arial", "sans-serif";
    font-size: 20px;
    margin-bottom: 0.3em;
}

label {
    font-family: 'Cantarell';
    //font-size: 0.8em;
    text-transform: uppercase;
    display: block;
    padding-bottom: 6px;
}
input, select {
    height: 40px;
}
input, select, textarea {
    line-height: 20px;
    box-sizing: border-box;
    font-family: 'Cantarell';
    margin-bottom: 15px;
}
input[type="password"], input[type="text"], input[type="number"], input[type="email"], input[type="date"], input[type="file"], select, textarea {
    color: #666666;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 2px 0 0 12px;
    -webkit-appearance: none;
}

textarea {
    padding-top: 9px;
    }
input.ui-button {
    text-transform: uppercase;
    background-color: $brand-color;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 3px;
    background-image: none;
    color: #ffffff;
    font-weight: bold;
    border: 0;
}
.dim
{
    height:100%;
    width:100%;
    position:fixed;
    left:0;
    top: 100px;
    z-index:1 !important;
    background-color:black;
    filter: alpha(opacity=35);
    -khtml-opacity: 0.35;
    -moz-opacity: 0.35;
    opacity: 0.35;
transition: all 1.5s ease;
}

.login-overlay-wrapper { width: 100%; top: 190px; left: 0px; position: fixed; z-index: 5; display: block;  }

.login-overlay {
    width: 350px;
    height: 330px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    background-color: $background-color;
    border: 1px solid $background-color; //$grey-color-light;
    color: $text-color;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-radius: 6px;
}
.login-overlay h2 {
    margin-top: 1em;
    margin-bottom: 0.8em;
}

h2 {
    //text-align: left;
}

 
 .app-icon {
    float: left;
    margin-right: 16px;
    margin-bottom: 10px;
}
.app-name {
    font-size: 14px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 10px;
}
.app-description {
    text-align: left;
}
.app-col h3 {
    border-bottom: 0px;
    margin-bottom: 0.5em;
}

.app-section {
}
.app-section h2 {
    margin-top: 0.5em;
    border-bottom: 0px;
    margin-bottom: 0.5em;
    font-size: 20px;
}
.app-section .app-cell {
    margin-bottom: 30px;
    padding-left: 0px;
    padding-right: 20px;
    }

.error {
    color: #990011;
}

.hide {
    display: none;
}

.fullheight {
    display: table;
    height: 100%;
    width: 100%;
    border-collapse: collapse;
}
.fullheight .inner {
    display: table-row;
    height: 100%;
}
.index-page-content {
display: table-row;
}
html.index body, html.index {
height: 100%;
}
html.index {
max-height: 1000px;
}
.panels-wrapper {
display: table-row;
}
.index-page-content .wrapper {
display: table-cell;
height: 100%;
padding-top: 200px;
padding-bottom: 100px;
}
@include height-media-query($med-height) {
.index-page-content .wrapper {
padding-top: 170px;
padding-bottom: 70px;
}
}
@include height-media-query($small-height) {
.index-page-content .wrapper {
padding-top: 130px;
padding-bottom: 30px;
}
.logo-large {
    min-height: 110px;
    max-height: 180px;
}
}
